/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

input:focus{
    outline: none!important;
}
input{
    color: black;
    font-size: 12px;
}
.p-error{
    color: red;
    font-size: 8px;
  }
.receiving-ride{
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    // height: 30%;
    height: 55%;
    --background: white;
    border-top-right-radius: 20px;
}
.ride-accepted{
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    height: 55%;
    // height: 30%;
    --background: white;
    border-top-right-radius: 20px;
}
ion-accordion-group{
    ion-accordion {
        .ion-accordion-toggle-icon {
            font-size: small;
            color: black;
        }
    }
}
ion-loading.custom-loading {
    --background: #e3edff;
    --spinner-color: #3cccc4;
  
    color: #3CCCC4;
  }
  ion-modal::part(backdrop) {
    background-color: transparent;
}